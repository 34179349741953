<template>
  <div
    :class="{
      'show-page': !isStarted,
      'show-menu': isShowMenu,
    }"
  >
    <div
      class="b-wrapper"
      :class="{
        'b-wrapper-base': isBaseWrapper,
      }"
    >
      <div class="b-wrapper__overlay js-overlay" @click="closeMenu"></div>
      <div class="page-start js-page-start" @click.stop="isStarted = true">
        <div class="page-start__title">
          <div class="page-start__title__top">click</div>
          <div class="page-start__title__bottom">to start</div>
        </div>
      </div>
      <div class="b-content">
        <the-header @closeMenu="closeMenu" @showMenu="showMenu"></the-header>
        <router-view></router-view>
      </div>
    </div>
    <div
      id="dialog-content"
      class="b-popup b-popup__question"
      style="display: none"
    >
      <div class="b-popup__title">Есть проект? Давайте обсудим!</div>
      <app-form></app-form>
    </div>
    <div
      id="dialog-content1"
      class="b-popup b-popup__finish"
      style="display: none"
    >
      <div class="b-popup__title">Спасибо</div>
      <div class="b-popup__text">Ваше сообщение отправлено</div>

      <div class="b-popup__wrap-btn">
        <app-button>Вернуться на сайт</app-button>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from "vue";
import { Fancybox } from "@fancyapps/ui";
import TheHeader from "@/views/TheHeader.vue";
import { useRoute, useRouter } from "vue-router";
import AppButton from "@/components/AppButton.vue";
import AppForm from "@/views/AppForm.vue";

const isStarted = ref(true);
const isShowMenu = ref(false);
const router = useRouter();
const route = useRoute();

router.beforeEach((to, from, next) => {
  if (isShowMenu.value) {
    isShowMenu.value = !isShowMenu.value;
  }
  next();
});

const isBaseWrapper = computed(
  () => route.name === "cases" || route.name === "caseDetail"
);

function closeMenu() {
  isShowMenu.value = false;
}
function showMenu() {
  isShowMenu.value = true;
}
onMounted(() => {
  Fancybox.bind("[data-fancybox]");
});
</script>
<style lang="scss">
@import "styles/reset.css";
@import "@fancyapps/ui/dist/fancybox/fancybox.css";
@import "styles/fonts";
@import "styles/_variables";
@import "styles/_default.scss";
@import "styles/_main.scss";
@import "styles/_header.scss";
@import "styles/_cols.scss";
@import "styles/_footer.scss";
@import "styles/_popup.scss";
@import "styles/_new-style.scss";
@import "styles/_team.scss";
</style>
